import React from 'react';
import './_FeeItem.scss';

const FeeItem = () => (
    <div className="FeeItem">
        <img src="/media/circleWarning.svg" alt="Icon Network" />
        <p>Estimated Fee: <strong>~0.80 MILKADA</strong></p>
    </div>
);

export default FeeItem;