import React from 'react';
import './_SeparationLine.scss';

const SeparationLine = () => (
    <div className="SeparationLine">
        <hr className="LineOne" />
        <img src="/media/chevronDown.svg" alt="Chevron Down" />
        <hr className="LineTwo" />
    </div>
);

export default SeparationLine;