import React from 'react';
import './_ProgressBar.scss';

const getPercent = (value?: number, max?: number) => {
    if (value === undefined || value === null || max === undefined || max === null) return 0;
    return (sanitizeValue(value, max) / max) * 100;
};

const sanitizeValue = (value?: number, max?: number) => {
    if (value === undefined || value === null || max === undefined || max === null) return 0;
    if (value < 0) return 0;
    if (value > max) return max;
    return value;
};

const ProgressBar = ({ value, max }) => (
    <div className="ProgressBar">
        <div className="ProgressBarTitle">
            <p>Waiting for confirmations...</p>
            <p>{sanitizeValue(value, max)} of {max}</p>
        </div>
        {/* begin::Progress */}
        <div className="progress h-10px bg-white bg-opacity-10">
            <div
                className="progress-bar bg-theme"
                role="progressbar"
                style={{ width: `${getPercent(value, max)}%` }}
                aria-valuenow={getPercent(value, max)}
                aria-valuemin={0}
                aria-valuemax={100}
            ></div>
        </div>
        {/* end::Progress */}
    </div>
);

export default ProgressBar;