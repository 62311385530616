import React from 'react';
import './_NetworkItem.scss';

const NetworkItem = ({ placeText, iconNetwork, nameNetwork }) => (
    <div className="NetworkItem">
        <p>{placeText}</p>
        <div className="ItemContent">
            <img className="IconNetwork" src={iconNetwork} alt="Icon Network" />
            <h3>{nameNetwork}</h3>
        </div>
    </div>
);

export default NetworkItem;