import React from 'react';
import './_ErrorItem.scss';

const ErrorItem = ({message}) => (
    <div className="ErrorItem">
        <img src="/media/circleWarning.svg" alt="Icon Network" />
        <p>{message}</p>
    </div>
);

export default ErrorItem;