import React from 'react';
import './_InfoLabel.scss';

const InfoLabel = ({ theme, labeltext, infoText }) => {
    const className = `InfoLabel ${theme}`;

    return (
        <div className={className}>
            <p>{labeltext}</p>
            <p><strong>{infoText}</strong></p>
        </div>
    );
};

export default InfoLabel;