import React from 'react';
import NetworkItem from '../../atoms/NetworkItem/NetworkItem';
import ProgressBar from '../../atoms/ProgressBar/ProgressBar';
import './_NetworkConfirm.scss';

const NetworkConfirm = ({ iconNetwork, nameNetwork, conf, maxConf }) => (
    <div className="NetworkConfirm">
        <NetworkItem placeText={false} iconNetwork={iconNetwork} nameNetwork={nameNetwork} />
        <ProgressBar value={conf} max={maxConf}/>
    </div>
);

export default NetworkConfirm;