import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "../_start/layout/core";
import { MasterLayout } from "../_start/layout/MasterLayout";
import { Routes } from "./routing/Routes";
import { ErrorsPage } from "./modules/errors/ErrorsPage";

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  return (
    <BrowserRouter basename={basename}>
      <ThemeProvider>
        <Switch>
          <Route path="/error" component={ErrorsPage} />
          <MasterLayout>
            <Routes />
          </MasterLayout>
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export { App };
