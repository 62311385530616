import React from 'react';
import TokenArrow from '../../atoms/TokenArrow/TokenArrow';
import InfoLabel from '../../atoms/InfoLabel/InfoLabel';
import './_TokenAmount.scss';

const TokenAmount = ({amount}) => (
    <div className="TokenAmount">
        <div className="AmountContent">
            <p>Token Amount</p>
            <div className="AmountNumber">
                <TokenArrow iconTokenOne="/media/iconMilkomeda.svg" iconTokenTwo="/media/iconCardano.svg" />
                <h3>{amount} MILKADA </h3>
            </div>
        </div>
        <InfoLabel theme="ThemeRight" labeltext="Estimated Fee" infoText="0.8 ADA" />
    </div>
);

export default TokenAmount;