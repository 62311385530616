/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from "react";
import { StepperComponent } from "../../../../_start/assets/ts/components/_StepperComponent";
import { KTSVG } from "../../../../_start/helpers";
import FeeItem from "../../atoms/FeeItem/FeeItem";
import InfoLabel from "../../atoms/InfoLabel/InfoLabel";
import NetworkItem from "../../atoms/NetworkItem/NetworkItem";
import SeparationLine from "../../atoms/SeparationLine/SeparationLine";
import Subtitle from "../../atoms/Subtitle/Subtitle";
import WalletButton from "../../atoms/WalletButton/WalletButton";
import TokenWrap from "../../molecules/TokenWrap/TokenWrap";
import NetworkConfirm from "../../molecules/NetworkConfirm/NetworkConfirm";

import "./_CustomWizardStyle.scss";
import TokenAmount from "../../molecules/TokenAmount/TokenAmount";
import TransactionDetail from "../../molecules/TransactionDetail/TransactionDetail";

// Ethereum
import { ethers } from "ethers";
import SideChainBridge from '../../../../abi/SidechainBridge.json';
import ErrorItem from "../../atoms/ErrorItem/ErrorItem";
import CardanoLoader from "../../../../CardanoLoader";

export function CustomWizardPage() {
  // Config
  const CARDANO_NETWORK = process.env.REACT_APP_BRIDGE_ADDRESS === 'mainnet' ? 1 : 0;
  const BRIDGE_ADDRESS = process.env.REACT_APP_BRIDGE_ADDRESS;
  const GAS_LIMIT = parseInt(process.env.REACT_APP_GAS_LIMIT ?? '221000');
  const UNWRAPPING_PROPOSALS_ENDPOINT = process.env.REACT_APP_UNWRAPPING_PROPOSALS_ENDPOINT;
  const CARDANO_EXPLORER_URL = process.env.REACT_APP_CARDANO_EXPLORER_URL;
  const CARDANO_MAX_CONFIRMATION = parseInt(process.env.REACT_APP_CARDANO_MAX_CONFIRMATION ?? '10');
  const ETH_MAX_CONFIRMATION = parseInt(process.env.REACT_APP_ETH_MAX_CONFIRMATION ?? '1');

  const cardanoAssetId = ethers.utils.hexZeroPad([], 32);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [ethProvider, setEthProvider] = useState<ethers.providers.JsonRpcProvider | null>(null);
  const [ethSigner, setEthSigner] = useState<ethers.providers.JsonRpcSigner | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [ethBalance, setEthBalance] = useState('');
  const [cardanoPrice, setCardanoPrice] = useState(0);
  const [unwrapAmount, setUnwrapAmount] = useState('');
  const [targetCardanoAddress, setTargetCardanoAddress] = useState('');
  const [ethTxReceipt, setEthTxReceipt] = useState<ethers.providers.TransactionReceipt | null>(null);
  const [cardanoTxId, setCardanoTxId] = useState('');
  const [cardanoConfirmation, setCardanoConfirmation] = useState(0);
  const [cardanoTxConfDate, setCardanoTxConfDate] = useState(new Date());

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();
    setCurrentStep(stepper.current.getCurrentStepIndex());
  };

  const nextStep = async () => {
    if (!stepper.current) {
      return;
    }


    stepper.current.goNext();
    setCurrentStep(stepper.current.getCurrentStepIndex());

    // Actions
    if (stepper.current.getCurrentStepIndex() === 3 && ethSigner !== null) {
      try {
        const accountAddr = await ethSigner.getAddress();
        const tx = await txUnwrappingBuilder(
          accountAddr,
          targetCardanoAddress,
          unwrapAmount,
          ethSigner);

        if (tx !== null) {
          const txResp = await ethSigner.sendTransaction(tx);
          nextStep();
          const txReceipt = await txResp.wait(1);
          setEthTxReceipt(txReceipt);
          startEthConfirmationUpdate(txReceipt);
          startCardanoConfirmationUpdate(txReceipt);
        }
      }
      catch (ex) {
        console.log(ex);
        prevStep();
      }
    }
  };

  const restart = async () => {

    if (stepper.current !== null) {
      stepper.current.goFirst();
      stepper.current.goNext();
      setCurrentStep(stepper.current.getCurrentStepIndex());
    }

    setEthBalance('');
    setCardanoPrice(0);
    setUnwrapAmount('');
    setTargetCardanoAddress('');
    setEthTxReceipt(null);
    setCardanoTxId('');
    setCardanoConfirmation(0);
    setCardanoTxConfDate(new Date());

    if (ethSigner !== null)
      setEthBalance(ethers.utils.formatEther(await ethSigner.getBalance()));
  };

  useEffect(() => {
    if (stepperRef.current !== null) {
      loadStepper();
    }
  }, [stepperRef]);

  const refreshCardanoPrice = async () => {
    // get Cardano ADA price via coingecko
    const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=cardano&vs_currencies=usd');
    const data = await response.json();
    setCardanoPrice(data['cardano']['usd']);
  };

  const getAbi = () => SideChainBridge['abi'];
  const txUnwrappingBuilder = async (fromAccount: string, toCardanoAddress: string, amount: string = '2.000', signer: ethers.providers.JsonRpcSigner) => {
    const txCount = await signer.getTransactionCount();

    if (txCount === null || ethProvider === null) {
      throw new Error("txBuilder txCount ethProvider can't be null / void");
    }
    const contractAbi = getAbi();
    const etherAmount = ethers.utils.parseEther(amount);
    const unwrappingRequest = {
      from: fromAccount,
      assetId: cardanoAssetId, // need to be upgrade to handle other tokens
      to: ethers.utils.hexlify(ethers.utils.toUtf8Bytes(toCardanoAddress)),
      amount: etherAmount
    };

    let iface = new ethers.utils.Interface(contractAbi);
    const data = iface.encodeFunctionData("submitUnwrappingRequest", [unwrappingRequest]);

    const txParams = {
      nonce: ethers.utils.hexlify(txCount),
      to: BRIDGE_ADDRESS,
      value: ethers.utils.hexlify(etherAmount),
      gasLimit: ethers.utils.hexlify(GAS_LIMIT),
      gasPrice: ethers.utils.hexlify(await ethProvider.getGasPrice()),
      data, // BufferLike
    }
    return txParams;
  };

  const connectMetamask = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, signer] = await initData();

    if (signer !== null) {
      nextStep();
    }
  };

  const initMetamaskConnection = async () => {
    try {
      // If user allowed access then we should be able to call this function
      await initData();
      nextStep();
    }
    catch {
      console.log("Metamask not connected");
    }
  };

  const loadCardano = async () => {
    await CardanoLoader.LoadAsync();
  };

  const onUnwrapAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => setUnwrapAmount(e.target.value);

  const getEstValue = () => unwrapAmount === '' ?
    (parseFloat(ethBalance) * cardanoPrice).toFixed(2) :
    (parseFloat(unwrapAmount) * cardanoPrice).toFixed(2);

  const getCardanoNetworkString = () => CARDANO_NETWORK === 1 ? 'mainnet' : 'testnet';

  const initData = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    setEthBalance(ethers.utils.formatEther(await signer.getBalance()));
    setEthSigner(signer);
    setEthProvider(provider);
    return [provider, signer];
  };

  const isCardanoAddressValid = () => {
    try {
      const address = CardanoLoader.Cardano?.Address.from_bech32(targetCardanoAddress);
      if (address?.network_id() !== CARDANO_NETWORK) return false;
      return true;
    }
    catch {
      console.log("Invalid Cardano address");
    }
    return false;
  };

  const isAmountMinimum = () => {
    if (unwrapAmount === '') {
      return false;
    }
    const amount = parseFloat(unwrapAmount);
    return amount >= 2;
  };

  const isFormUntouched = () => {
    return unwrapAmount === '' && targetCardanoAddress === '';
  }

  const isAmountMaximum = () => {
    if (unwrapAmount === '') {
      return false;
    }
    const amount = parseFloat(unwrapAmount);
    return amount <= parseFloat(ethBalance);
  };

  const startEthConfirmationUpdate = (txReceipt: ethers.providers.TransactionReceipt) => {
    const interval = setInterval(async () => {
      const ethTxHash = txReceipt?.transactionHash;
      if (ethTxHash === undefined) return;

      const newTxReceipt = await ethProvider?.getTransactionReceipt(ethTxHash);
      if (newTxReceipt !== undefined) {
        setEthTxReceipt(newTxReceipt);
        if (newTxReceipt.confirmations >= 10) {
          clearInterval(interval);
        }
      }
      else {
        clearInterval(interval);
      }
    }, 2000);
  };

  const startCardanoConfirmationUpdate = (txReceipt: ethers.providers.TransactionReceipt) => {
    const interval = setInterval(async () => {
      const unwrapProposalResult = await fetch(`${UNWRAPPING_PROPOSALS_ENDPOINT}?blockNumber=${txReceipt?.blockNumber}`);
      const unwrapProposalResultData = await unwrapProposalResult.json();
      if (unwrapProposalResultData.unwrappingProposals !== undefined) {
        const proposals = unwrapProposalResultData.unwrappingProposals as any[];
        const proposal = proposals.find(p => p.from === txReceipt.from);
        if (proposal !== undefined) {
          const cardanoTxId = proposal.mainchainTxId;
          if (cardanoTxId === '') return;

          const txInfoResult = await fetch(`https:/${getCardanoNetworkString()}-cardano.flint-wallet.com/v2/txs/get`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              txHashes: [cardanoTxId]
            })
          });
          const txInfoResultData = await txInfoResult.json();

          if (txInfoResultData[cardanoTxId] !== undefined) {
            const txInfo = txInfoResultData[cardanoTxId];
            const bestBlockResult = await fetch(`https://${getCardanoNetworkString()}-cardano.flint-wallet.com/v2/bestblock`);
            const bestBlockResultData = await bestBlockResult.json();
            const confirmations = bestBlockResultData.height - txInfo.block_num;
            setCardanoConfirmation(confirmations);
            if (confirmations >= 10) {
              setCardanoConfirmation(10);
              setCardanoTxId(cardanoTxId);
              setCardanoTxConfDate(new Date(txInfo.time));
              clearInterval(interval);
              nextStep();
            }
          }
        }
      }
    }, 20000);
  };

  useEffect(() => {
    initMetamaskConnection();
    refreshCardanoPrice();
    loadCardano();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="card card-custom PrincipalCard">
      {/* begin::Card Body */}
      <div className="card-body p-10 p-lg-15 p-xxl-30">
        {/* begin::Stepper 1 */}
        <div
          ref={stepperRef}
          className="stepper stepper-1  d-flex flex-column flex-xl-row flex-row-fluid"
          id="kt_stepper"
        >
          {/* begin::Aside */}
          <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px">
            {/* begin::Nav */}
            <div className="stepper-nav d-flex flex-column py-15">
              {/* begin::Step 1 */}
              <div
                className="stepper-item current"
                data-kt-stepper-element="nav"
              >
                <div className="stepper-wrapper">
                  <div className="stepper-icon">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">1</span>
                  </div>
                  <div className="stepper-label">
                    <h3 className="stepper-title">Connect Wallet</h3>
                    <div className="stepper-desc">
                      Setup Your Account Details
                    </div>
                  </div>
                </div>
              </div>
              {/* end::Step 1 */}

              {/* begin::Step 2 */}
              <div className="stepper-item" data-kt-stepper-element="nav">
                <div className="stepper-wrapper">
                  <div className="stepper-icon">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">2</span>
                  </div>
                  <div className="stepper-label">
                    <h3 className="stepper-title">Select Token to Unwrap</h3>
                    <div className="stepper-desc">To move back to Cardano</div>
                  </div>
                </div>
              </div>
              {/* end::Step 2 */}

              {/* begin::Step 3 */}
              <div className="stepper-item" data-kt-stepper-element="nav">
                <div className="stepper-wrapper">
                  <div className="stepper-icon">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">3</span>
                  </div>
                  <div className="stepper-label">
                    <h3 className="stepper-title">Confirm Unwrapping</h3>
                    <div className="stepper-desc">Use your wallet to sign the transaction</div>
                  </div>
                </div>
              </div>
              {/* end::Step 3 */}

              {/* begin::Step 4 */}
              <div className="stepper-item" data-kt-stepper-element="nav">
                <div className="stepper-wrapper">
                  <div className="stepper-icon">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">4</span>
                  </div>
                  <div className="stepper-label">
                    <h3 className="stepper-title">Blockchain Confirmations</h3>
                    <div className="stepper-desc">This should take around 3-5 minutes</div>
                  </div>
                </div>
              </div>
              {/* end::Step 4 */}

              {/* begin::Step 5 */}
              <div className="stepper-item" data-kt-stepper-element="nav">
                <div className="stepper-wrapper">
                  <div className="stepper-icon">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">5</span>
                  </div>
                  <div className="stepper-label">
                    <h3 className="stepper-title">Completed!</h3>
                    <div className="stepper-desc">Review and Continue</div>
                  </div>
                </div>
              </div>
              {/* end::Step 5 */}
            </div>
            {/* end::Nav */}
          </div>
          {/* begin::Aside */}

          {/* begin::Content */}
          <div className="d-flex flex-row-fluid justify-content-center PrincipalContent">
            {/* begin::Form */}
            <form
              className="pt-10 w-100 w-md-400px w-xl-600px"
              noValidate
              id="kt_stepper_form"
            >
              {/* begin::Step 1 */}
              <div className="current" data-kt-stepper-element="content">
                <div className="w-100">
                  {/* begin::Heading */}
                  <div className="TitleContent">
                    <h3 className="StepTitle">
                      Connect Wallet
                    </h3>
                    <p className="Subtitle">
                      Having Issues?
                      <a href="#" className="Link" style={{ marginLeft: '5px' }}>
                        Get Help
                      </a>
                    </p>
                  </div>
                  {/* begin::Heading */}
                  <div className="StepContent" onClick={connectMetamask}>
                    <WalletButton iconWallet="/media/logos/iconMetamask.svg" nameWallet="Metamask" />
                  </div>
                </div>
              </div>
              {/* end::Step 1 */}

              {/* begin::Step 2 */}
              <div className="" data-kt-stepper-element="content">
                <div className="w-100">
                  {/* begin::Heading */}
                  <div className="TitleContent">
                    <h3 className="StepTitle">
                      Select Token to Unwrap
                    </h3>
                    <p className="Subtitle">
                      Having Issues?
                      <a href="#" className="text-primary fw-bolder" style={{ marginLeft: '5px' }}>
                        Get Help
                      </a>
                    </p>
                  </div>
                  {/* end::Heading */}

                  <div className="Step2Content">
                    <NetworkItem placeText="From" iconNetwork="/media/iconMilkomeda.svg" nameNetwork="Milkomeda" />
                    <TokenWrap amount={unwrapAmount} onAmountChange={onUnwrapAmountChange} maxAmount={ethBalance} />
                    <div className="LabelsContainer">
                      <InfoLabel theme="ThemeLeft" labeltext="Estimated value" infoText={`~${getEstValue()} USD`} />
                      <InfoLabel theme="ThemeRight" labeltext="Available balance" infoText={`${ethBalance} MILKADA`} />
                    </div>
                    <SeparationLine />
                    <div className="LabelsContainer Two">
                      <NetworkItem placeText="To" iconNetwork="/media/iconCardano.svg" nameNetwork="Cardano" />
                    </div>
                    <div className="TokenWrap" style={{ marginBottom: '12px' }}>
                      <div className="InputContainer">
                        <input
                          type="text"
                          className="form-control form-control-flush"
                          placeholder="Enter Cardano Address"
                          value={targetCardanoAddress}
                          onChange={(e) => setTargetCardanoAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    <FeeItem />
                    {!isCardanoAddressValid() && !isFormUntouched() && <>
                      <div className="mb-4" />
                      <ErrorItem message={"Invalid Cardano Address."} />
                    </>}
                    {!isAmountMinimum() && !isFormUntouched() && <>
                      <div className="mb-4" />
                      <ErrorItem message={"Minimum unwrap amount is 2 MILKTADA."} />
                    </>}
                    {!isAmountMaximum() && !isFormUntouched() && <>
                      <div className="mb-4" />
                      <ErrorItem message={`Maximum unwrap amount is ${ethBalance} MILKTADA.`} />
                    </>}
                  </div>
                </div>
              </div>
              {/* end::Step 2 */}

              {/* begin::Step 3 */}
              <div className="" data-kt-stepper-element="content">
                <div className="w-100">
                  {/* begin::Heading */}
                  <div className="TitleContent">
                    <h3 className="StepTitle">
                      Confirm Unwrapping
                    </h3>
                    <p className="Subtitle">
                      Use your wallet to sign the transaction
                    </p>
                  </div>
                  {/* begin::Heading */}

                  <div className="Step2Content"></div>
                </div>
              </div>
              {/* end::Step 3 */}

              {/* begin::Step 4 */}
              <div className="" data-kt-stepper-element="content">
                <div className="w-100">
                  {/* begin::Heading */}
                  <div className="TitleContent">
                    <h3 className="StepTitle">
                      Blockchain Confirmations
                    </h3>
                  </div>
                  {/* begin::Heading */}

                  <div className="Step2Content">
                    <Subtitle subtitleIcon="/media/iconMessage.svg" subtitleText="Details" />
                    <TokenAmount amount={unwrapAmount} />

                    <Subtitle subtitleIcon="/media/iconClock.svg" subtitleText="Network Confirmations" />

                    <NetworkConfirm iconNetwork="/media/iconMilkomeda.svg" nameNetwork="Milkomeda" conf={ethTxReceipt?.confirmations} maxConf={ETH_MAX_CONFIRMATION} />
                    <hr className="Separator" />
                    <NetworkConfirm iconNetwork="/media/iconCardano.svg" nameNetwork="Cardano" conf={cardanoConfirmation} maxConf={CARDANO_MAX_CONFIRMATION} />
                  </div>

                </div>
              </div>
              {/* end::Step 4 */}

              {/* begin::Step 5 */}
              <div data-kt-stepper-element="content">
                <div className="w-100">
                  {/* begin::Heading */}
                  <div className="TitleContent">
                    <img className="IconNetwork" src="/media/iconCheck.svg" alt="Icon Check" />
                    <h3 className="StepTitle">Transaction Completed!</h3>
                    <p className="Subtitle">
                      See transaction details
                    </p>
                  </div>
                  {/* end::Heading */}
                  <div className="Step2Content">
                    <TransactionDetail amount={unwrapAmount} txId={cardanoTxId} date={cardanoTxConfDate} explorerUrl={CARDANO_EXPLORER_URL} />
                  </div>

                </div>
              </div>
              {/* end::Step 5 */}

              {/* begin::Actions */}
              <div className="d-flex justify-content-between pt-10">
                <div className="mr-2">
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                    data-kt-stepper-action="submit"
                    onClick={restart}
                  >
                    Restart
                    <KTSVG
                      path="/media/icons/duotone/Navigation/Right-2.svg"
                      className="svg-icon-4 ms-2"
                    />
                  </button>
                  {currentStep === 2 &&
                    <button
                      type="button"
                      className="btn btn-lg btn-primary fw-bolder py-4 ps-8 me-3"
                      data-kt-stepper-action="next"
                      onClick={nextStep}
                      disabled={!isCardanoAddressValid() || !isAmountMinimum() || !isAmountMaximum()}
                    >
                      Next Step
                      <KTSVG
                        path="/media/icons/duotone/Navigation/Right-2.svg"
                        className="svg-icon-4 ms-1"
                      />
                    </button>}
                </div>
              </div>
              {/* end::Actions */}
            </form>
            {/* end::Form */}
          </div>
          {/* end::Content */}
        </div>
        {/* end::Stepper 1 */}
      </div >
      {/* end::Card Body */}
    </div >
  );
}

export default CustomWizardPage;