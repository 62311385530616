import React from 'react';
import './_WalletButton.scss';

const WalletButton = ({ iconWallet, nameWallet }) => (
    <div className="WalletButton">
        <div className="ButtonContent">
            <img className="IconWallet" src={iconWallet} alt="Icon Wallet" />
            <div className="TextContent">
                <h3>{nameWallet}</h3>
                <p>Connect your {nameWallet} wallet</p>
            </div>
        </div>
        <img className="IconArrow" src="/media/chevronRight.svg" alt="Chevron Right" />
    </div>
);

export default WalletButton;