import React from 'react';
import TokenArrow from '../../atoms/TokenArrow/TokenArrow';
import './_TransactionDetail.scss';

const TransactionDetail = ({amount, txId, explorerUrl, date}) => (
    <div className="TransactionDetail">

        <div className="Wrapper1">
            <div className="box1">Time</div>
            <div className="box1">Unwrapping</div>
            <div className="box1">Amount</div>
            <div className="box1">Fee</div>
            <div className="box1">Explorer</div>
        </div>
        <div className="Wrapper2">
            <div className="box2"><h3><strong>{date.toLocaleString()}</strong></h3></div>
            <div className="box2"><TokenArrow iconTokenOne="/media/iconMilkomeda.svg" iconTokenTwo="/media/iconCardano.svg" /></div>
            <div className="box2"><h3>{amount} ADA</h3></div>
            <div className="box2"><h3>0.8 ADA</h3></div>
            <div className="box2"><a href={`${explorerUrl}/${txId}`} target="_blank" rel="noreferrer"><img className="iconLink" src="/media/iconExternalLink.svg" alt="Explorer Link" /></a></div>
        </div>
    </div>
);

export default TransactionDetail;