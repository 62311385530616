import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { FallbackView } from "../../_start/partials";
import CustomWizardPage from "../components/organisms/Wizard/CustomWizardPage";

export function Routes() {
  const GeneralPageWrapper = lazy(
    () => import("../modules/general/GeneralPageWrapper")
  );
  const DocsPageWrapper = lazy(() => import("../modules/docs/DocsPageWrapper"));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/general" component={GeneralPageWrapper} />
        <Route path="/docs" component={DocsPageWrapper} />
        <Route exact path="/bridge" component={CustomWizardPage} />
        <Redirect exact from="/" to="/bridge" />
        <Redirect to="error/404" />
      </Switch>
    </Suspense>
  );
}
