import React from "react";
import { Footer } from "./components/Footer";
// import { HeaderWrapper } from "./components/header/HeaderWrapper";
// import { Sidebar } from "./components/Sidebar";
// import { Toolbar } from "./components/Toolbar";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { MasterInit } from "./MasterInit";
import { PageDataProvider } from "./core";

import "../../../src/app/scss/app.scss";

const MasterLayout: React.FC = ({ children }) => {
  return (
    <PageDataProvider>
      <div className="d-flex flex-column flex-root PrincipalContent">
        <div className="d-flex flex-column flex-column-fluid">
          <div className="Header">
            <img
              src="/media/logos/LogoMilkomeda.svg" alt="Logo Milkomeda"
            />
          </div>
          <div className="content fs-6 d-flex flex-column-fluid" id="kt_content">
            <Content>
              <div className="Dashboard">{children}</div>
            </Content>
          </div>
        </div>
        <Footer />
      </div>
      <ScrollTop />
      <MasterInit />
    </PageDataProvider>
  );
};

export { MasterLayout };
