import React from 'react';
import './_TokenWrap.scss';

const TokenWrap = ({ amount, onAmountChange, maxAmount }) => (
    <div className="TokenWrap">
        <div className="InputContainer">
            <input
                type="text"
                className="form-control form-control-flush"
                placeholder="1,000 MILKADA"
                onChange={onAmountChange}
                value={amount}
            />
            <span className="btnMax" onClick={() => onAmountChange({ target: { value: maxAmount } })}>Max</span>
        </div>
        <div className="SelectorContainer">
            <img src="/media/iconMilkomeda.svg" alt="Icon Network" />
            <select className="form-select form-select-white" aria-label="Select example">
                <option>MILKADA</option>
            </select>
        </div>
    </div>
);

export default TokenWrap;