import React from 'react';
import './_Subtitle.scss';

const Subtitle = ({ subtitleIcon, subtitleText }) => (
    <div className="Subtitle">
        <img src={subtitleIcon} alt="Icon" />
        <p>{subtitleText}</p>
    </div>
);

export default Subtitle;