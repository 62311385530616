import React from 'react';
import './_TokenArrow.scss';

const TokenArrow = ({ iconTokenOne, iconTokenTwo }) => (
    <div className="TokenArrow">
        <img className="IconToken" src={iconTokenOne} alt="Icon Token" />
        <img className="Arrow" src="/media/ArrowRight.svg" alt="Arrow Right" />
        <img className="IconToken" src={iconTokenTwo} alt="Icon Token" />
    </div>
);

export default TokenArrow;